import React from 'react'

import Layout from '../../components/layout'
import Wizard from '../../components/wizard'

import { ReactComponent as Trash } from '../../assets/icons/trash.svg'

const PrototypePage = () => (
  <Layout>
    <Wizard title="Tag types" backTo="/onboarding-1/choose-template" nextTo="/onboarding-1/done">
      <div className="btn btn-outline-primary">Create new tag Type</div>
      <hr/>
      <p className="d-flex align-items-center"><b>Products</b><span className="btn btn-secondary btn-sm ml-2"><Trash height={14} /></span></p>
      <hr/>
      <p className="d-flex align-items-center"><b>Segments</b><span className="btn btn-secondary btn-sm ml-2"><Trash height={14} /></span></p>
      <hr/>
      <p className="d-flex align-items-center"><b>Tags</b><span className="btn btn-secondary btn-sm ml-2"><Trash height={14} /></span></p>
      <hr/>
    </Wizard>
  </Layout>
)

export default PrototypePage
